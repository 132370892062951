import CardContent from '../../../components/Content/components/CardContent';
import { createApiCardTemplate } from '../../../components/Content/components/ApiCardTemplate';

const title = 'Radon in der Stadt';

const mainRadonstadt = {
  name: 'Radon Stadt',
  defaultRoute: '/Radon_Stadt',
  type: CardContent,
  template: createApiCardTemplate(title, 'radon-stadt', 'radon-stadt'),
};
export default mainRadonstadt;
