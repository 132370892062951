import CardContent from '../../../components/Content/components/CardContent';
import { createApiCardTemplate } from '../../../components/Content/components/ApiCardTemplate';

const title = 'Downloads';

const downloadsRadonstadt = {
  name: 'Downloads',
  defaultRoute: '/Radon_Stadt/Downloads',
  type: CardContent,
  template: createApiCardTemplate(title, 'downloads', 'radon-stadt'),
};
export default downloadsRadonstadt;
