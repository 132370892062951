import { Card } from 'react-bootstrap';
import CardContent from '../../../../../components/Content/components/CardContent';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PENDING = 0;
const SUCCESS = 1;
const ERROR = 2;

const STATUS_TEXT = ['Anmeldung', 'Anmeldung abgeschlossen', 'Anmeldung nicht erfolgreich'];

const Template = (): JSX.Element => {
  const { id, key } = useParams<{ id: string; key: string }>();

  const [status, setStatus] = useState(PENDING);

  useEffect(() => {
    if (id && key) {
      fetch(`/api/confirm.php?id=${id}&key=${key}`)
        .then(response => {
          setStatus(response.status === 200 ? SUCCESS : ERROR);
        })
        .catch(() => {
          setStatus(ERROR);
        });
    }
  }, [id, key]);

  let content = <p />;
  if (status === SUCCESS) {
    content = (
      <p>
        Ihre Anmeldung zu einer kostenfreien Radonmessung ist vollständig und korrekt abgeschlossen. Wir werden Sie per
        Mail benachrichtigen, wenn die Versendung der Messgeräte ansteht.
      </p>
    );
  } else if (status === ERROR) {
    content = (
      <p>
        Ein Fehler bei der Rückmeldung ist aufgetreten. Melden Sie sich bitte unter{' '}
        <a href={'mailto:fv-arbeitsplatz@kemski-bonn.de'}>fv-arbeitsplatz@kemski-bonn.de</a>
      </p>
    );
  }

  return (
    <>
      <Card.Header>
        <h4>{STATUS_TEXT[status]}</h4>
      </Card.Header>
      <Card.Body>{content}</Card.Body>
    </>
  );
};

const anmeldungRueckmeldung = {
  name: 'Anmeldung Rückmeldung',
  defaultRoute: '/Radon_Stadt/Rückmeldung/:id/:key',
  type: CardContent,
  template: Template,
};
export default anmeldungRueckmeldung;
