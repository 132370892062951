import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Menu, MenuEntry } from '../../content/menu/types';
import menu from '../../content/menu/menu';
import { Link } from 'react-router-dom';

function Navigation(): JSX.Element {
  const createMenu = (isDropdown: boolean, menu: Menu) => {
    return menu.map((entry: MenuEntry, i: number) => {
      if ('entries' in entry) {
        const submenu = createMenu(true, entry.entries);
        const title = entry.rootEntry ? (
          <Link style={{ textDecoration: 'none', color: 'inherit' }} to={entry.rootEntry.defaultRoute}>
            {entry.name}
          </Link>
        ) : (
          entry.name
        );

        return (
          <NavDropdown renderMenuOnMount={true} key={i} style={{ color: 'white' }} id={entry.name.trim()} title={title}>
            {submenu}
          </NavDropdown>
        );
      } else {
        const page = 'entry' in entry ? entry.entry : entry;
        const Elem = isDropdown ? NavDropdown.Item : Nav.Link;
        return (
          <Elem key={i} as={Link} to={page.defaultRoute}>
            {entry.name}
          </Elem>
        );
      }
    });
  };

  const menuComponents = createMenu(false, menu);

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="mb-4">
      <Navbar.Toggle aria-controls="navbar" />
      <Container>
        <Navbar.Collapse id="navbar">
          <Nav>{menuComponents}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
