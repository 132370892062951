import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

export type Props = {
  pageName: string;
  module: string | undefined;
};

type ContentResponse = {
  content: string;
};

const ApiContent = ({ pageName, module }: Props): JSX.Element => {
  const [data, setData] = useState(null as ContentResponse | null);
  const [error, setError] = useState(null as boolean | null);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const url = `/api/content.php?page=${pageName}${module ? `&module=${module}` : ''}`;

        const response = await fetch(url);
        if (!response.ok) {
          setError(true);
          return;
        }
        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(true);
      }
    };

    fetchContent();
  }, [pageName, module]);

  if (error) return <div>Leider ist beim Lader der Seite ein Fehler aufgetreten</div>;

  const content = data?.content;
  if (!content) return <div></div>;

  const sanitizedContent = () => ({
    __html: DOMPurify.sanitize(content, { ADD_ATTR: ['target'] }),
  });

  return <div dangerouslySetInnerHTML={sanitizedContent()} />;
};

export default ApiContent;
