import home from './pages/home';
import { Route } from './types';
import unserePhilosophie from './pages/Unternehmen/UnserePhilosophie';
import ansprechpartnerUndQualifikationen from './pages/Unternehmen/AnsprechpartnerUndQualifikationen';
import error from './error';
import kooperationen from './pages/Unternehmen/Kooperationen';
import publikationen from './pages/Unternehmen/Publikationen';
import datenschutz from './pages/Datenschutz';
import impressum from './pages/Impressum';
import referenzenUndAuftraggeber from './pages/Unternehmen/ReferenzenUndAuftraggeber';
import leistungsuebersicht from './pages/Leistungsuebersicht/Leistungsuebersicht';
import radon2020 from './pages/Radon2020/Beschreibung';
import privathaushalte from './pages/Leistungsuebersicht/Privathaushalte';
import radonAmArbeitsplatz from './pages/Leistungsuebersicht/RadonAmArbeitsplatz';
import radonInDerBodenluft from './pages/Leistungsuebersicht/RadonInDerBodenluft';
import radonInOeffentlichenGebaeuden from './pages/Leistungsuebersicht/RadonInOeffentlichenGebaeuden';
import radionuklideInDerUmwelt from './pages/Leistungsuebersicht/RadionuklideInDerUmwelt';
import anmeldung from './pages/RadonStadt/Anmeldung';
import datenverarbeitungRadonstadt from './pages/RadonStadt/Datenverarbeitung';
import anmeldungBestaetigung from './pages/RadonStadt/Anmeldung/pages/AnmeldungBestaetigung';
import anmeldungRueckmeldung from './pages/RadonStadt/Anmeldung/pages/AnmeldungRueckmeldung';
import gebaeudeinformationen from './pages/RadonStadt/Gebaeudeinformationen';
import passwortVergessen from './pages/RadonStadt/Gebaeudeinformation/pages/PasswortVergessen';
import passwortZuruecksetzen from './pages/RadonStadt/Gebaeudeinformation/pages/PasswortZuruecksetzen';
import ablaufRadonstadt from './pages/RadonStadt/Ablauf';
import beschreibungRadonstadt from './pages/RadonStadt/Beschreibung';
import aufstellungsanleitungRadonstadt from './pages/RadonStadt/Austellungsanleitung';
import downloadsRadonstadt from './pages/RadonStadt/Downloads';
import mainRadonstadt from './pages/RadonStadt/RadonStadt';

const routes: Route[] = [
  // Unternehmen
  unserePhilosophie,
  ansprechpartnerUndQualifikationen,
  kooperationen,
  publikationen,
  referenzenUndAuftraggeber,
  // Leistungsuebersicht
  leistungsuebersicht,
  privathaushalte,
  radonAmArbeitsplatz,
  radonInDerBodenluft,
  radonInOeffentlichenGebaeuden,
  radionuklideInDerUmwelt,
  // Radon 2020
  radon2020,
  datenschutz,
  impressum,
  // RadonStadt
  anmeldung,
  anmeldungBestaetigung,
  datenverarbeitungRadonstadt,
  anmeldungRueckmeldung,
  gebaeudeinformationen,
  passwortVergessen,
  passwortZuruecksetzen,
  ablaufRadonstadt,
  aufstellungsanleitungRadonstadt,
  beschreibungRadonstadt,
  downloadsRadonstadt,
  mainRadonstadt,
  // Home and Error as fallback
  home,
  error,
];

export default routes;
