import { Card } from 'react-bootstrap';
import CardContent from '../../../../../components/Content/components/CardContent';
import { Link } from 'react-router-dom';

const template = (
  <>
    <Card.Header>
      <h4>Anmeldung eingegangen</h4>
    </Card.Header>
    <Card.Body>
      <p>
        Sie haben sich soeben über unsere Internetseite zu einer kostenfreien Radonmessung angemeldet. Um
        sicherzustellen, dass die Anmeldung auch von Ihnen kam, erhalten Sie in wenigen Augenblicken eine Mail, in der
        Sie dies bitte durch Anklicken eines Aktivierungslinks bestätigen. Erst dann ist die Anmeldung vollständig und
        korrekt abgeschlossen.
      </p>
      <p>Vielen Dank.</p>
      <p>
        <Link to={'/'}>Startseite</Link>
      </p>
    </Card.Body>
  </>
);

const anmeldungBestaetigung = {
  name: 'Anmeldung Bestätigung',
  defaultRoute: '/Radon_Stadt/Anmeldung_Bestätigung',
  type: CardContent,
  template,
};
export default anmeldungBestaetigung;
