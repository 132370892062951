import { Card } from 'react-bootstrap';
import CardContent from '../../../components/Content/components/CardContent';

const template = (
  <>
    <Card.Header>
      <h4>Radon 2020 - Forschungsvorhaben des Bundesamts für Strahlenschutz (BfS)</h4>
    </Card.Header>
    <Card.Body>
      <p>
        <b>Ermittlung der aktuellen Verteilung der Radonkonzentration in deutschen Wohnungen</b>
      </p>
      <p>
        Das Vorhaben ist <b>abgeschlossen</b>.
      </p>
      <p>
        Alle Daten liegen nun <b>ausschließlich beim Bundesamt für Strahlenschutz</b> (BfS) vor. Das BfS beantwortet
        gern Anfragen zu den eigenen Teilnehmerdaten, zum Messprogramm und zum Thema Radon.{' '}
      </p>
      <p>
        Kontaktaufnahme per Mail über epost(at)bfs.de oder schriftlich über Bundesamt für Strahlenschutz, Postfach 10 01
        49, 38201 Salzgitter.
      </p>
      <p>
        Wir möchten uns noch einmal bei allen Teilnehmern für ihr Interesse an dem Messprogramm bedanken. Der
        Abschlussbericht des Forschungsvorhabens wurde vom BfS veröffentlicht und steht unter{' '}
        <a href="https://nbn-resolving.de/urn:nbn:de:0221-2024062644650">
          https://nbn-resolving.de/urn:nbn:de:0221-2024062644650
        </a>{' '}
        zum Herunterladen als pdf-Datei bereit.
      </p>
    </Card.Body>
  </>
);

const radon2020 = {
  name: 'Radon 2020',
  defaultRoute: '/Radon_2020',
  type: CardContent,
  template,
};
export default radon2020;
